import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  redirectDelay: number = 0;
  provider: string = '';

  constructor(
  
    protected router: Router) {
    
  }

  ngOnInit(): void {
    this.logout(this.provider);
  }

  logout(provider: string): void {
   
    localStorage.clear();
  }


}
