import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from './auth.service'

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

	constructor(private router: Router, private auth: AuthService){}
    //DEF0003489 changes starts- role permission @Sangamanath 31-Jan-2024
	// canActivate(){
		async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
	//DEF0003489 changes ends- role permission @Sangamanath 31-Jan-2024
		// console.log("Inside canActivate() AuthGaurd Service");
	    if (!this.auth.isAuthenticated()) {
	    	window.alert("You don't have permission to view this page"); 
	    	this.router.navigate(['auth']);
	      return false;
	    }
		//DEF0003489 changes starts- role permission @Sangamanath 31-Jan-2024
		const url = state.url;
		if (!(url === "/" || url === "/change")) {
		  return await this.handleNavigationEvent(url);
		}
		//DEF0003489 changes ends- role permission @Sangamanath 31-Jan-2024
	    return true;
	  }

	canActivateChild(){
		// console.log("Inside canActivateChild() AuthGaurd Service");
	    if (!this.auth.isAuthenticated()) {
	    	window.alert("You don't have permission to view this page"); 
	    	this.router.navigate(['auth']);
	      return false;
	    }
	    return true;
	  }
//DEF0003489 changes starts- role permission @Sangamanath 31-Jan-2024
	  async handleNavigationEvent(url: string): Promise<boolean> {
		const navigationUrlSegments = url.split('/').slice(1, 3);
		return this.performAuthorizationCheck(navigationUrlSegments);
	  }
	  
	  private areSegmentsEqual(segments1: string[], segments2: string[]) {
		return segments1.length === segments2.length && segments1.every((segment, index) => segment.toLowerCase() === segments2[index].toLowerCase());
	  }
	  
	  async performAuthorizationCheck(requestedUrlSegments: string[]): Promise<boolean> {
		if (!(requestedUrlSegments[1] === 'landing-dashboard' || requestedUrlSegments[2] === 'landing-dashboard')) {
		  try {
			const resOfMenu = await this.auth.getMenus().toPromise();
			let count = 0;
	  
			for (let i = 0; i < resOfMenu.data.length; i++) {
			//DEF0003489 changes starts - @Sangamanath Feb-02-2024
			if (resOfMenu.data[i].children != undefined) {
			//DEF0003489 changes ends - @Sangamanath Feb-02-2024
			  const children = resOfMenu.data[i].children;
	  
			  children.some(child => {
				const allowedUrlSegments = child.link.split('/').slice(1, 3);
	  
				if (this.areSegmentsEqual(requestedUrlSegments, allowedUrlSegments)) {
				  count++;
				}
			  });
			}
			}//DEF0003489 changes starts - @Sangamanath Feb-02-2024
	  
			if (count === 0) {
			  window.alert("User is not Authorized to access this page...");
			  this.router.navigate(['/pages']);
			  return true;
			}
			return true;
		  } catch (error) {
			console.error('Error fetching menus:', error);
			return false;
		  }
		} else {
		  return true;
		}
	  }
	  //DEF0003489 changes ends- role permission @Sangamanath 31-Jan-2024
}
