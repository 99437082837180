import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { NbLoginComponent } from './@theme/components/auth/login/login.component';

import { AuthGuard } from './auth/auth-guard.service';
import { RequestPasswordComponent } from './@theme/components/auth/request-password/request-password.component';
import { ChangePasswordComponent } from './@theme/components/auth/change-password/change-password.component';
import { ResetPasswordComponent } from './@theme/components/auth/reset-password/reset-password.component';
import { NbAuthComponent } from './@theme/components/auth/auth.component';
import { OtpComponentComponent } from './@theme/components/auth/otp-component/otp-component.component';

export const routes: Routes = [
  { path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule' },

  {
    path: '',
    component: NbAuthComponent,
    children: [
        {
          path: '',
          component: NbLoginComponent,
        },
        {
          path: 'login',
          component: NbLoginComponent,
        },
        {
          path: 'change',
          component: ChangePasswordComponent,
        },
        {
          path: 'forgot',
          component: RequestPasswordComponent,
        },
        {
          path: 'reset',
          component: ResetPasswordComponent,
        },
        //DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login
        {
          path: 'enterotp',
          component: OtpComponentComponent,
        },
        //DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login
       
      
    ],
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  //useHash: false,
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
