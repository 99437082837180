// changes Begin: Created new service file by senthil
//   #BR0008 #Pratibha  #SPRINT1/2018 #18Aug2018
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class DashboardBroadcaster {
    private currentprojectCode = null;
    private broadcaster = new BehaviorSubject<object>({}); // Creating event emitter object
    broadcasterAsObservable = this.broadcaster.asObservable(); // Making event emitter object as observable

    // Broadcasting projectCode to listeners who are subscribed
    broadcastProjectCode(projectCode: String) {
        this.currentprojectCode = projectCode;
        this.broadcaster.next(projectCode);
    }

    // Get current project id
    getprojectCode(): String {
        return this.currentprojectCode;
    }

    //changes Begin: #Senthilkumar - Profile broadcaster
    //#DEF00332 #Pratibha  #SPRINT1/2018 #24Sept2018
    private loggedInUserProfileInfo = null;
    private profileBroadcaster = new BehaviorSubject<object>({}); // Creating event emitter object
    profileBroadcasterAsObservable = this.profileBroadcaster.asObservable(); // Making event emitter object as observable

    // Broadcasting projectCode to listeners who are subscribed
    broadcastProfileInfo(profileInfo: Object) {
        this.loggedInUserProfileInfo = profileInfo;
        this.broadcaster.next(profileInfo);
    }
    //changes End:#Senthilkumar - Profile broadcaster
    //#DEF00332 #Pratibha  #SPRINT1/2018 #24Sept2018
}
// changes End: Created new service file by senthil
//   #BR0008 #Pratibha  #SPRINT1/2018 #18Aug2018