<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <span style=" font-size: 23px;">{{org}}</span>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<!--CR00413 Changes Start added system name @Sujitha 2-Nov-2021-->
<!--CR00413-pending Changes start added dropdown @Sujitha 30-Nov-2021-->
<!-- <div class="row" style="margin-right: -645px; font-size: 23px; padding-top: 12px; cursor: pointer;" (click)="ExistingConfig()">
  Select System
</div> -->
<!--DEF0002713 Changes start added margin for select system @Sujitha 7-Mar-2022-->
<!-- <div class="row" style="margin-right: -645px; font-size: 23px; padding-top: 4px;"> -->
  <div class="row" style="margin-right: -535px; font-size: 23px; padding-top: 4px;">
    <!--DEF0002713 Changes End added margin for select system @Sujitha 7-Mar-2022-->
  <!--DEF0002572 Changes start added field name to bind the selected value @Sujitha 9-Dec-2021-->
  <!-- <nb-select [(ngModel)]="selectedsys"  placeholder="Select System" (selectedChange)="ExistingConfig()"> -->
  <!--DEF0002974 Changes start: made auto search field @Himanshu 28-Sep-2022-->
    <!-- <nb-select [(ngModel)]="selectedsys" [(selected)]="selectedOption"  placeholder="Select System" (selectedChange)="ExistingConfig()"> -->
      <!--DEF0002572 Changes End added field name to bind the selected value @Sujitha 9-Dec-2021-->
      <!-- <nb-option *ngFor='let systemC of configdata'
          [value]="systemC.System_Code">
          {{ systemC.System_Code }}
      </nb-option> -->
  <!--DEF0002712 changes start added code for checkbox @Sujitha 7-Apr-2022-->
    <!-- </nb-select> -->
  <!-- </nb-select>&nbsp;&nbsp; -->
  <!--DEF0002974-RE Changes start: added ngModel in the input tag @Himanshu 07-10-2022-->
  <!-- <input #autoInput [formControl]="inputFormControl" nbInput type="text" placeholder="Select System"
  [nbAutocomplete]="auto"/>
<nb-autocomplete #auto [ngModel]="selectedsys" (selectedChange)="ExistingConfig($event)"> -->
  <!-- //DEF0003537  changes starts - @Sangamanath 1st_march_2024  -->
  <!-- <input #autoInput [formControl]="inputFormControl" nbInput type="text" placeholder="Select System"
  [nbAutocomplete]="auto" [(ngModel)]="selectedsys"/> -->
  <input #autoInput [formControl]="inputFormControl" nbInput type="text" placeholder="Select System"
  [nbAutocomplete]="auto" [(ngModel)]="selectedsys" (click)="getTargetSystems()"/>
  <!-- //DEF0003537  changes ends - @Sangamanath 1st_march_2024  -->
<nb-autocomplete #auto (selectedChange)="ExistingConfig($event)">
  <!--DEF0002974-RE Changes end: added ngModel in the input tag @Himanshu 07-10-2022-->
  <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.System_Code">
    {{ option.System_Code }}
  </nb-option>
</nb-autocomplete>&nbsp;&nbsp;&nbsp;&nbsp;
<!--DEF0002974 Changes end: made auto search field @Himanshu 28-Sep-2022-->
  <div style="padding-top:10px" *ngIf="selectsystem">
    <nb-checkbox [checked]="enable_checked" (checkedChange)="selecttoggle($event)">
    </nb-checkbox>
  </div>
 <!--DEF0002712 changes End added code for checkbox @Sujitha 7-Apr-2022-->
</div>
<!--CR00413-pending Changes End added dropdown @Sujitha 30-Nov-2021-->
<!--CR00413 Changes End added system name @Sujitha 2-Nov-2021-->

<div class="header-container">
  <nb-actions size="small">
   <!-- #CR00299 - Changes Begin - #03 Oct 2020 #Pavithra - Commenting Out these
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    #CR00299 - Changes End - #03 Oct 2020 #Pavithra -->

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
     
      <div class="dropdown" style="float:right;">
        <button class="dropbtn">
          <img id="user-img" class="images" [src]="profileImageUrl"> &nbsp;&nbsp;
        <!--#CR00376 #Pavithra #22Jun2021- Changes for New Layout <span style="font-size: 23px;"> {{empFName}} {{empLName}}</span>&nbsp;&nbsp;-->
        <span style="font-size: 23px; color:white"> {{empFName}} {{empLName}}</span>&nbsp;&nbsp;
        </button>
        <div class="dropdown-content">
          <a (click)="changePassword($event)">Change Password</a>
          <a (click)="logout($event)">Logout</a>
        </div>
      </div>
      <!-- //CR00473 changes start: added download icon @Himanshu 04-03-2023 -->
       <!-- DEF0003693 changes starts - @Sangamanath 5th july 2024 downloading extension in Breez -->
      <!-- <div> -->
        <div class="dropdown">
        <!-- <button class="btn btn-dark btn-sm" type="button" style="font-size: x-large;" (click)="downloadExe()"><i data-toggle="tooltip" title="Download file" class="fa fa-download table-eye view-icon"></i></button> -->
        <button class="btn btn-dark btn-sm" type="button" style="font-size: x-large;"><i data-toggle="tooltip" class="fa fa-download table-eye view-icon"></i></button>
          <div class="dropdown-content">
            <a (click)="xpathExtractor()" style="white-space: nowrap;">Breez XpathExtractor</a>
            <a (click)="downloadExe()" style="white-space: nowrap;">Breez Desktop Application</a>
          </div>
      <!-- DEF0003693 changes ends - @Sangamanath 5th july 2024 downloading extension in Breez  -->
      </div>
      <!-- //CR00473 changes end: added download icon @Himanshu 04-03-2023 -->
      <!--DEF0003140 changes start: added help icon @Himanshu 17-01-2023-->
      <div style="margin-right: 10px;margin-left: 10px;">
        <a href="http://www.tesquirel.com/Breez_UserGuide/" target="_blank"><img src="assets/images/questionblue.png" alt="" style="width: 40px" title="help"></a>
      </div>
      <!--DEF0003140 changes end: added help icon @Himanshu 17-01-2023-->
      <div style="margin-top: 11px;">
        <a class="fa fa-home" style="font-size:40px;" (click)="viewChange()"></a>
      </div>
    </nb-action>
  </nb-actions>
</div>
