import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
    //debugger;
  }
  
  public getToken(): string {
    
    return localStorage.getItem('token');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }

    // if (!request.headers.has('Content-Type')) {
    //     request = request.clone({ 
    //       headers: request.headers.set('Content-Type', 'application/json') 
    //     });
    // }

    // setting the accept header

    // request = request.clone({ 
    //   headers: request.headers.set('Accept', 'application/json') 
    // });


    return next.handle(request)
    .do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
        // console.log('successfully authenticated (y)')
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          console.log('Unauthorized, We have decided to kick you out');
          alert("You don't have permission to view this page");
          return this.router.navigateByUrl('auth/login');          
        }
      }
    });;
  }
}