<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
      <div class="col-md-6 login-form-1">
          <h3>Change Password</h3>
          
          <form (ngSubmit)="changePass()" #changePassForm="ngForm">
            <div class="form-group">
              <label for="exampleInputEmail1" class="label">Email address</label>
              <!--DEF0003180 changes start: added default value @Himanshu 15-02-2023-->
              <!-- <input  name="email"  [(ngModel)]="user.email_Id" id="input-email" 
               #email="ngModel"
                 class="form-control" placeholder="Email address" pattern=".+@.+\..+"
                 [class.form-control-danger]="email.invalid && email.touched"
                 [required]="true"
                > -->
                <input  name="email"  [(ngModel)]="email_Id" id="input-email" 
               #email="ngModel"
                 class="form-control" placeholder="Email address" pattern=".+@.+\..+"
                 [class.form-control-danger]="email.invalid && email.touched"
                 [required]="true"
                >
                <!--DEF0003180 changes end: added default value @Himanshu 15-02-2023-->
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
                Email is required!
              </small>
              <small class="form-text error"
                     *ngIf="email.invalid && email.touched && email.errors?.pattern">
                Email should be the real one!
              </small>
  
            </div>

            <div class="form-group">
              <label for="exampleInputPassword1" class="label">username</label>
              <!--DEF0003180 changes start: added default value @Himanshu 15-02-2023-->
              <!-- <input type="email" nbInput fullWidth name="username" id="user-name"
               [(ngModel)]="user.username" #username="ngModel"
              class="form-control" placeholder="Username"
              [class.form-control-danger]="username.invalid && username.touched"
              [required]="true"
              autofocus placeholder="username"> -->
              <input type="email" nbInput fullWidth name="username" id="user-name"
               [(ngModel)]="userName" #username="ngModel"
              class="form-control" placeholder="Username"
              [class.form-control-danger]="username.invalid && username.touched"
              [required]="true"
              autofocus placeholder="username">
              <!--DEF0003180 changes end: added default value @Himanshu 15-02-2023-->
              <small class="form-text error" *ngIf="username.touched && username.invalid && username.errors?.required">
                Username is required!
              </small>
            </div>

            <div class="form-group">
              <label for="exampleInputPassword1" class="label">Current Password</label>
              <input type="password" nbInput fullWidth type="password"
              id="input-password"
              name="currentpassword"
              [(ngModel)]="user.currentpassword"
              required
              class="form-control"
              #currentpassword="ngModel"
              [class.form-control-danger]="currentpassword.invalid && currentpassword.touched"
              [required]="true"
              [minlength]=0
              [maxlength]=10 placeholder="current password">
           
            <small class="form-text error" *ngIf="currentpassword.invalid && currentpassword.touched && currentpassword.errors?.required">
              Current Password is required!
            </small>
           
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1" class="label">New Password</label>
            <input type="password" nbInput fullWidth id="exampleInputPassword1" name="password"
            [(ngModel)]="user.password"
            required
            class="form-control" #password="ngModel"
            [class.form-control-danger]="password.invalid && password.touched"
            [required]=true
            [pattern]="passPattern"
            [minlength]=0
            [maxlength]=10 placeholder="New Password">
            <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
                Password is required!
            </small>
            <small
                class="form-text error"
                *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
                Password should contains
                from 0
                to 10
                characters
            </small>
            <!-- //CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field -->
            <div class="text-red-400 text-sm" *ngIf="changePassForm.controls['password'].dirty">
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['password'].value?.match('^(?=.*[A-Z])'),'text-red': !changePassForm.controls['password'].value?.match('^(?=.*[A-Z])')}">At least one uppercase letter.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['password'].value?.match('(?=.*[a-z])'),'text-red': !changePassForm.controls['password'].value?.match('(?=.*[a-z])')}">At least one lowercase letter.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['password'].value?.match('(.*[0-9].*)'),'text-red': !changePassForm.controls['password'].value?.match('(.*[0-9].*)')}">At least one digit.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['password'].value?.match('(?=.*[!@#$%^&*])'),'text-red': !changePassForm.controls['password'].value?.match('(?=.*[!@#$%^&*])')}">At least one special character.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['password'].value?.match('.{8,}'),'text-red': !changePassForm.controls['password'].value?.match('.{8,}')}">At least 8 characters long.</span>
            </div>
            <!-- //CR00496 changes ends - @Sangamanath 09-Jan-2024 added validation on password field -->
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1" class="label">Confirm Password</label>
            <input type="password" nbInput fullWidth type="password" id="input-re-password"
            class="form-control form-control-lg last" placeholder="Confirm Password" name="rePass" [(ngModel)]="user.confirmPassword"
            required
            class="form-control" #rePass="ngModel"
            [class.form-control-danger]="(rePass.invalid || password.value != rePass.value) && rePass.touched"
            [required]=true [pattern]="passPattern" placeholder="Confirm Password"><!--//CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field-->
            <small class="form-text error"
            *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required">
            Password confirmation is required!
            </small>
              <!--DEF0003180 changes start: matching the password and confirm password @Himanshu 15-02-2023-->
              <small
              class="form-text error"
              *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required">
              New Password does not match the confirm password.
            </small>
             <!--DEF0003180 changes end: matching the password and confirm password @Himanshu 15-02-2023-->
             <!-- //CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field -->
             <div class="text-red-400 text-sm" *ngIf="changePassForm.controls['rePass'].dirty">
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['rePass'].value?.match('^(?=.*[A-Z])'),'text-red': !changePassForm.controls['rePass'].value?.match('^(?=.*[A-Z])')}">At least one uppercase letter.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['rePass'].value?.match('(?=.*[a-z])'),'text-red': !changePassForm.controls['rePass'].value?.match('(?=.*[a-z])')}">At least one lowercase letter.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['rePass'].value?.match('(.*[0-9].*)'),'text-red': !changePassForm.controls['rePass'].value?.match('(.*[0-9].*)')}">At least one digit.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['rePass'].value?.match('(?=.*[!@#$%^&*])'),'text-red': !changePassForm.controls['rePass'].value?.match('(?=.*[!@#$%^&*])')}">At least one special character.</span>
              <span class="block" [ngClass]="{'text-green': changePassForm.controls['rePass'].value?.match('.{8,}'),'text-red': !changePassForm.controls['rePass'].value?.match('.{8,}')}">At least 8 characters long.</span>
            </div>
            <!-- //CR00496 changes ends - @Sangamanath 09-Jan-2024 added validation on password field -->
          </div>
          <div class="form-group row">
              <div class="col-sm-6">
                <button type="submit" class="btn btn-primary active" [disabled]="submitted || !changePassForm.valid"
                >Update</button>
             </div>
             <div class="col-sm-6">
               <!--DEF0003180 changes start: added routing @Himanshu 15-02-2023-->
              <!-- <button type="submit" class="btn btn-primary active">Home</button> -->
              <button routerLink="../dashboard" class="btn btn-primary active">Home</button>
              <!--DEF0003180 changes end: added routing @Himanshu 15-02-2023-->
            </div>
          </div>
          </form>
  
    </div>
    </div>
    </div>
  