import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { JSEncrypt } from "jsencrypt";
import { AuthServiceLogin } from '../auth.service';
import { UserService } from '../../header/user.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class NbLoginComponent implements OnInit {

  constructor(protected router: Router, private authService: AuthServiceLogin) { }

  ngOnInit(): void {
  }
  
  user: any = {};
  submitted: boolean = false;
  domainSubdomains;
  subdomains;
  org_Code;
  user_Id;
  isLogin: boolean = true;
  Organization: boolean = false;
  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  errors: string[] = [];
  messages: string[] = [];
debugger;
  login(): void {
    //CR0407 Changes Start removibg debugger @Sujitha 4-Oct-2021
    // debugger;
    //CR0407 Changes End removibg debugger @Sujitha 4-Oct-2021
    //DEF0002712 changes start removing localstorage value @Sujitha 8-Apr-2022
    //DEF0003491 changes start - @Sangamanath 17-FEB-2024
    //DEF0003491 changes start - @Sangamanath 19-FEB-2024 
    localStorage.removeItem("landingpagevalue");
    localStorage.removeItem("landing-system-Code");
    // localStorage.clear(); // Due to this line in header the value of token is null and in java it will create new token and user can login
    //If we applied clear on localStorage, it will clear all stored data in localStorage, due to this
    // recent stored token also getting cleared, and stored required data(Org_code, user_id) also clearing from
    //localStorage, but now only we will clear "token" so, it will not effect to the other data which is stored.
    //Reason to removed "clear()" function :- When we applied it is clearing org_code along with token value
    // Due to Org_code=null then we need to pass Org_code to python api or java api, due to its value is null then these api's are throwing an error,
    localStorage.removeItem("token");
    //DEF0003491 changes ends - @Sangamanath 19-FEB-2024
    //DEF0003491 changes ends - @Sangamanath 17-FEB-2024
    //DEF0002712 changes End removing localstorage value @Sujitha 8-Apr-2022
    this.errors = this.messages = [];
    this.authService.getPublickey().subscribe(
      (resp: any) => {
        if (resp) {
          this.debugger
          let previouspwd = this.user.password
          let encryptor = new JSEncrypt();
          encryptor.setPublicKey(resp.data);
          let pwd = encryptor.encrypt(this.user.password); 
          this.user.password = pwd
          this.authService.login(this.user)
            .subscribe(
              (data: any) => {
                if (data) {
                  localStorage.setItem('token', data.headers.get('Authorization'));
                  localStorage.setItem('UId', data.body.id.user_Id);
                  this.org_Code = data.body.id.org_Code;
                  this.user_Id = data.body.id.user_Id;
                 //DEF0002169 Changes Start added new fields for new requiremnet #Sujitha #9-Apr-2021
                  localStorage.setItem("user_Id",this.user_Id)
                  //CR00512 changes starts - @Sangamanath 30th JULY 2024 update permission at project level 
                  localStorage.setItem("employeeCode_fromLogin", data.body.employee_Code);
                  //CR00512 changes ends - @Sangamanath 30th JULY 2024 update permission at project level
                 //DEF0002169 Changes End added new fields for new requiremnet #Sujitha #9-Apr-2021
                  this.isLogin = !this.isLogin;
                  if (!this.isLogin) this.getDomainSubDomain();
                  if (this.isLogin) {
                    this.submitted = true;
                    setTimeout(() => {
                      //DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login
                      // return this.router.navigateByUrl('pages');
                      // this.user.password = previouspwd;
                      this.authService.getKeyValue()
                      .subscribe(
                        (data: any) => {
                          let keyValuCode='N';
                          const matchingItem = data.data.find(item => item.Org_Code === this.org_Code);
                          if (matchingItem) {
                            keyValuCode = matchingItem.KeyValue_Val_Cd;
                         }
                          if (keyValuCode==='Y') {
                            localStorage.setItem("user_name", this.user.email)
                            this.authService.generateOtpEmail1(this.user)
                              .subscribe(
                                (data: any) => {
                                  if (data) {
                                    setTimeout(() => {
                                      alert(data.data);
                                      return this.router.navigateByUrl('enterotp');
                                    }, this.redirectDelay);
                                  }
                                },
                                error => {
      
                                  alert("Username or EmailId is not correct");
      
                                  this.submitted = false;
                                  this.user = {};
                                });
                          }else{
                            return this.router.navigateByUrl('pages');
                          }
                        },
                        error => {
                          this.submitted = false;
                          this.user = {};
                        });
                      //DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login
                    }, this.redirectDelay);
                  }
                  this.user.password = previouspwd 
                }
              },
              error => {
              
                alert(error.error.message);
                this.submitted = false;
                this.user = {};
              });;
        }
      }
    )   
  }

  userChecked(event){
     this.Organization = true;
  }

  userunChecked(event){
    this.Organization = false;
  }

  getDomainSubDomain() {
    if (this.org_Code) {
      this.authService.getDomainSubDomain(this.org_Code, this.user_Id).subscribe((data) => {
        this.domainSubdomains = data.data;
        this.user.domain_Code = this.domainSubdomains[0].domain_Code;
         //CR00359 Chnages Start setting locally org code and domain code #Sujitha #2-Apr-2021
         localStorage.setItem("org_Code",this.org_Code);
         localStorage.setItem("domain_Code",this.user.domain_Code);
         //CR00359 Chnages End setting locally org code and domain code #Sujitha #2-Apr-2021
        this.getSubdomains();
      })
    }
  }

  getSubdomains() {
    let domain_Code = this.user.domain_Code;
    let data = this.domainSubdomains;
    this.subdomains = data.filter(function (data) {
      return data.domain_Code === domain_Code
    })
    if (this.subdomains.length === 1) {
      this.user.subdomain_Code = this.subdomains[0].subdomain_Code;
      //DEF0002169 Changes Start added new fields for new requiremnet #Sujitha #9-Apr-2021
      localStorage.setItem("subdomain_Code",this.user.subdomain_Code)
      //DEF0002169 Changes End added new fields for new requiremnet #Sujitha #9-Apr-2021
      this.login();
    }
  }

  gotoForgetPass() {
    this.router.navigateByUrl('forgot');
  }

}
