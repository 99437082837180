import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js'; 

import 'rxjs/add/operator/map'
import { environment } from '../../../../environments/environment';

@Injectable()

export class AuthServiceLogin {
	emailid: any;
	user: any;
	data: any;
	salt: string; 

	constructor(private http: HttpClient, private router: Router) {
		this.salt = "Te$@u!reL";
	}
	getBaseUrl() {  
		return 'http://3.6.189.154:8080/';
		// return 'http://192.168.0.102:8080/';  
	 }  
	login(val): Observable<HttpResponse<any>> {
		var body = {
			...val
		};
		return this.http
			.post<any>(environment.apiUrl  + `auth/login`, body, { observe: 'response' })	
			.map((response: HttpResponse<any>) => {	
				sessionStorage.setItem('org_Code', response.body.id.org_Code);
				sessionStorage.setItem('user_id', response.body.id.user_Id);
				// sessionStorage.setItem('employee_Code', response.body.id.employee_Code);
				return response;
				console.log(response);
			})
	}

	generateOtpEmail(val): Observable<HttpResponse<any>> {
		var body = {
			...val
		}
		return this.http
			.get<any>(environment.apiUrl + `auth/getotp?user_Name=` + val.username + '&email_Id=' + val.email)
			.map((response) => {
				return response;
			})
	}

	resetPassWithOtp(val): Observable<HttpResponse<any>> {
		var body = {
			'user_Name': val.username,
			'email_Id': val.email,
			'otp': val.otp,
			'new_Password': val.password

		}
		return this.http
			.put<any>(environment.apiUrl + `auth/resetpasswithotp`, body, { observe: 'response' })
			.map((response: HttpResponse<any>) => {
				return response;
			})
	}
	getDomainSubDomain(org_Code, user_Id): Observable<any> {
		return this.http
			.get<any>(environment.apiUrl + `getsubdomainbasedonuserid?org_Code=${org_Code}&user_Id=${user_Id}`)
			.map((response) => {
				return response;
			})
	}

	getUser() {
		let userID = sessionStorage.getItem('user_id');
		return this.http
			.get<any>(environment.apiUrl + `usersubdomainrole?user_Id=${userID}`)
			.map((response) => {
				this.user = response.data[0].user_Name;
				this.emailid = response.data[0].email_Id;
				return response.data
			});
	}

	changepassword(val): Observable<HttpResponse<any>> {
		var body = {
			'user_Name': val.username,
			'email_Id': val.email_Id,
			'old_Password': val.currentpassword,
			'new_Password': val.password,
		}
		return this.http
			.put<any>(environment.apiUrl + `resetpassword`, body, { observe: 'response' })
			.map((response: HttpResponse<any>) => {
				return response;
			});
	}
	
	getPublickey(): Observable<any> {
		return this.http.get<any>(environment.apiUrl + `auth/publickey`)
			.map((response) => {
				return response;
			})
	}
//DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login
	enterOtp(val): Observable<HttpResponse<any>> {
		var body = {
			'user_Name': val.username,
			'email_Id': val.username,
			'otp': val.otp,

		}
		return this.http
			.put<any>(environment.apiUrl + `auth/enterotp`, body, { observe: 'response' })
			.map((response: HttpResponse<any>) => {
				return response;
			})
	}

	generateOtpEmail1(val): Observable<HttpResponse<any>> {
		return this.http
			.get<any>(environment.apiUrl + `auth/getotp?user_Name=` + val.email + '&email_Id=' + val.email)
			.map((response) => {
				return response;
			})
	}

	getKeyValue(){
		return this.http.get<any>(environment.apiUrl+`keyvalue?KeyValue_Type_Cd=OTP-Required-IND&KeyValue_Lang_Cd=E`)
		.map((response) => {
			return response;
		})
	}
	//DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login
}

