//CR00413 Changes start added changes for model @Sujitha 2-Nov-2021
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-modal',
  templateUrl: 'config-model.component.html',
  styleUrls: ['config-model.component.scss'],
})

export class ConfigurationModalComponent implements OnInit {
  source: LocalDataSource;
  localData;
  settings = {

    columns: {

      'System_Code': { 'title': 'System Code', 'width': '0%' },
      'System_Name': { 'title': 'System Name', 'width': '20%' },
      'System_Type': { 'title': 'System Type', 'width': '40%' },
      'url': { 'title': 'URL', 'width': '40%' },

    },
    actions: {

      position: 'left',
      edit: false,
      delete: false,
      custom: [
        {
          name: 'view',
          title: '<i data-toggle="tooltip" title="Select System" class="nb-square-outline"></i>',
          filter: false,

        },

      ],
    },
  };
  onCustom(event) {
  
    localStorage.setItem("landing-system-Code", event.data.System_Code);
    localStorage.setItem("landingpagevalue", "true")
    let syscode = event.data.System_Code
    this.http.get<any>(environment.apiUrl + `configuration?System_Code=${syscode}`).subscribe(res => {
      this.closeModal();
      //DEF0002572 Changes start changed the url @Sujitha 9-Dec-2021
      // this.router.navigate(['/pages/configuration/view']);
      this.router.navigate(['/pages/configuration/list']);
      //DEF0002572 Changes End changed the url @Sujitha 9-Dec-2021

    }, err => {
      alert(err.error.message);
    });


  }
  onCreate() {
    this.closeModal();
    this.router.navigate(['/pages/configuration/create']);
  }

  constructor(
    private activeModal: NgbActiveModal,
    private http: HttpClient,
    private router: Router,
  ) {
  }

  ngOnInit() {

    this.http.get<any>(environment.apiUrl + `configuration`).subscribe(res => {
      this.localData = res.data;
      this.source = new LocalDataSource(res.data);
      window.setTimeout(() => {
        try {
          let prevPageInfo = JSON.parse(window.sessionStorage.getItem("prevPageInfo"));
          if (prevPageInfo && this.router.url === prevPageInfo["path"]) {
            this.source.setPaging(prevPageInfo.pageInfo.page, prevPageInfo.pageInfo.perPage, true);
            window.sessionStorage.removeItem("prevPageInfo");
          } else {
            this.source.setPaging(1, 5, true);
          }
        } catch (err) {
          console.error("Unable to set to previous state of page -- " + err);
        }
      }, 100);

    }, err => {
      alert(err.error.message);
    });

  }
  closeModal() {
    this.activeModal.close();
  }

}
//CR00413 Changes End added changes for model @Sujitha 2-Nov-2021