import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { UserService } from './user.service';
import { DashboardBroadcaster } from './dashboard.broadcast.service';
import 'rxjs/Rx';
//CR00413 Changes Start imported new modules @Sujitha 2-Nov-2021
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationModalComponent } from '../../../pages/Org ArteFacts/configuration/model/config-model.component';
//CR00413 Changes End imported new modules @Sujitha 2-Nov-2021
//DEF0002974 changes start: imported new modules @Himanshu 28-09-2022
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ViewChild } from '@angular/core';
import { DownloadService } from '../../../pages/services/download.service';
//DEF0002974 changes end: imported new modules @Himanshu 28-09-2022
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [UserService,DashboardBroadcaster]
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  org: any[];
  orgname;
  orgCode;
  username;
  userphoto;
  empCode;
  result;
  empFName;
  empLName;
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  //CR00413-pending Declaration start @Sujitha 30-Nov-2021
  configdata = [];
  selectedsys;
  //CR00413-pending Declaration End @Sujitha 30-Nov-2021
  selectedOption;//DEF0002572 declared new variable @Sujitha 9-Dec-2021
  //DEF0002712 declaration start @Sujitha 7-Apr-2022
  enable_checked: boolean= false;
  selectsystem: boolean= false;
  //DEF0002712 declaration End @Sujitha 7-Apr-2022
  //DEF0002974 changes start: added new variable @Himanshu 28-09-2022
  @ViewChild('autoInput') autocompleteEle;
  filteredOptions$: Observable<any[]>;
  inputFormControl: FormControl;
  //DEF0002974 changes start: added new variable @Himanshu 28-09-2022
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private router: Router,
    //CR00413 Changes start imported new module @Sujitha 2-Nov-2021
    private modalService: NgbModal,
    //CR00413 Changes End imported new module @Sujitha 2-Nov-2021
    private http: HttpClient,
    private downloadService: DownloadService 
    ) {

  }

  ngOnInit() {
        //DEF0002974 changes start: added FormControl @Himanshu 28-09-2022
        this.inputFormControl = new FormControl();
        //DEF0002974 changes end: added FormControl @Himanshu 28-09-2022
    this.userService.getUsers()
      .subscribe(
        data => {
          this.username = data;
          this.empCode = this.username[0].employee_Code;
          this.userService.getEmpDetail(this.empCode)
            .subscribe(
              data => {
                this.result = data;
                this.empFName = this.result[0].emp_First_Name;
                this.empLName = this.result[0].emp_Last_Name;
              
              });
         
          this.downloadProfilePic(environment.apiUrl + `manageemp/getempPhoto?emp_Code=${this.empCode}`)
            .subscribe((data) => {
              let fileTemp = new Blob([data], { type: 'image/png' });
              let reader: any;
              reader = new FileReader();
              reader.onload = (e) => {
                document.getElementById('user-img')
                  .setAttribute('src', e.target.result);
              }
            
              let result = reader.readAsDataURL(fileTemp);
            }, error => {
            
            });
        });
    
    this.userService.getOrgname()
      .subscribe(
        data => {
          this.orgname = data;
          this.org = this.orgname[0].org_Name;
        
        });

    let orgcode = sessionStorage.getItem('org_Code');
    this.downloadProfilePic(environment.apiUrl + `manageorg/getorglogo?org_Code=${orgcode}`)
      .subscribe((data) => {
        let fileTemp = new Blob([data], { type: 'image/png' });
        let reader: any;
        reader = new FileReader();
        reader.onload = (e) => {
          document.getElementById('org-img')
            .setAttribute('src', e.target.result);
        }
        let result = reader.readAsDataURL(fileTemp);
      }, error => {
       
      });
    //CR00413-pending Changes start added API @Sujitha 30-Nov-2021
    this.http.get<any>(environment.apiUrl + `configuration`).subscribe(res => {

      this.configdata=res.data;
       //DEF0003071 changes start: added condition for modal @Himanshu 22-Nov-2022
      if(res.data.length == 0){
        const objmodal = this.modalService.open(ConfigurationModalComponent);
      }
       //DEF0003071 changes end: added condition for modal @Himanshu 22-Nov-2022
      this.onChanges();//DEF0002974 added changes method @Himanshu 28-09-2022
      //DEF0002572 Changes start added condition for select system @Sujitha 9-Dec-2021
      let syscode=localStorage.getItem("landing-system-Code");
      //DEF0002712 changes start added condition for checkbox @Sujitha 7-Apr-2022
      // if(syscode != ''){
      // this.selectedOption = syscode;
      // }
      if(syscode !=null && syscode != ''){
        this.enable_checked = true;
        this.selectedOption = syscode;
        this.selectsystem = true;
        this.inputFormControl.setValue(syscode);//DEF0002974 storing system code
      }
      //DEF0002712 changes End added condition for checkbox @Sujitha 7-Apr-2022
      //DEF0002572 Changes End added condition for select system @Sujitha 9-Dec-2021

      // if(this.configdata == 0 ){
      //   this.router.navigate(['/pages/configuration/create']);
      // }
    }, err => {
      alert(err.error.message);
    });
    //CR00413-pending Changes End added API @Sujitha 30-Nov-2021

  }
  
  downloadProfilePic(api): Observable<any> {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .get(api, { headers: headers, responseType: 'arraybuffer' }
      ).map((response) => {
        return response;
      }).catch((e: any) => {
        return Observable.throw({ "Errors": e.json() });
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  viewChange() {
    this.router.navigate(['/pages']);
  }
 

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  logout(event) {
     //DEF0003491 changes starts - @Sangamanath 12-FEB-2024 logout issue
     this.userService.logOut().subscribe(res=>{});
      //DEF0003491 changes ends - @Sangamanath 12-FEB-2024 logout issue
    localStorage.clear();
    return this.router.navigateByUrl('');
  }

  changePassword(event) {
    return this.router.navigateByUrl('change');
  }
  //DEF0002712 changes start added checkbox @Sujitha 7-Apr-2022
  selecttoggle(event){
    this.enable_checked = event;//DEF0002974-RE storing the value for checkbox
    if(event == false){
     localStorage.removeItem("systemCode");
     localStorage.removeItem("landingpagevalue");
    //DEF0002829 changes start removed system code from localstorage @himanshu 28-06-2022
    localStorage.removeItem("landing-system-Code");
    //DEF0002829 changes End removed system code from localstorage @himanshu 28-06-2022
     this.selectedsys = null;
     this.autocompleteEle.nativeElement.value = "";//DEF0002974 reset the selected system
     this.router.navigate(['/pages/configuration/list']);

    }

  }
  //DEF0002712 changes End added checkbox @Sujitha 7-Apr-2022
  //CR00413 Changes Start added new method name @Sujitha 2-Nov-2021
  //DEF0002974 changes start: modified the method @Himanshu 28-09-2022
  // ExistingConfig(){
  //   //CR00413-pending Changes start added code for select system @Sujitha 30-Nov-2021
  //   //const objmodal = this.modalService.open(ConfigurationModalComponent);
  //   //DEF0002712 changes start added boolean flag @Sujitha 7-Apr-2022
  //   this.selectsystem= true;
  //   //DEF0002712 changes End added boolean flag @Sujitha 7-Apr-2022
  //   let syscode= this.selectedsys;
  //   localStorage.setItem("landing-system-Code", syscode);
  //   localStorage.setItem("landingpagevalue", "true");
  //   //DEF0002572 Changes start added condition for select system @Sujitha 9-Dec-2021
  //   // this.router.navigate(['/pages/configuration/view']);
  //   this.router.navigateByUrl('/', {skipLocationChange: true})
  //   .then(()=>this.router.navigate(['/pages/configuration/list', syscode]));
  //   //DEF0002572 Changes End added condition for select system @Sujitha 9-Dec-2021

  //   //CR00413-pending Changes start added code for select system @Sujitha 30-Nov-2021
  // }
  ExistingConfig(event) {
    this.selectsystem = true;
    this.enable_checked = true;
    let syscode = this.selectedsys;
    if (event) {
      localStorage.setItem("landing-system-Code", event);
      localStorage.setItem("landingpagevalue", "true");
      this.router.navigateByUrl('/pages', { skipLocationChange: true })
        .then(() => this.router.navigate(['/pages/configuration/list', event]));
    }
    else {
      localStorage.setItem("landing-system-Code", syscode);
      localStorage.setItem("landingpagevalue", "true");
      this.router.navigateByUrl('/pages', { skipLocationChange: true })
        .then(() => this.router.navigate(['/pages/configuration/list', syscode]));
    }
    //DEF0003193 changes start: removing data @Himanshu 16-03-2023
    localStorage.removeItem("projectCode");
    localStorage.removeItem('testdataresponce');
    localStorage.removeItem('testExecutionFormValue');
    //DEF0003193 changes end: removing data @Himanshu 16-03-2023
  }
  //DEF0002974 changes end: modified the method @Himanshu 28-09-2022
  //CR00413 Changes End added new method name @Sujitha 2-Nov-2021
    //DEF0002974 changes start: added logic for auto search @Himanshu 28-09-2022
    onChanges() {
      this.filteredOptions$ = this.inputFormControl.valueChanges
        .pipe(
          startWith(''),
          map(parent => {
            return parent ? this.filterPRList(parent) : this.configdata.slice()
          })
        );
    }
    filterPRList(name) {
      return this.configdata.filter(state =>
        state.System_Code.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }
    //DEF0002974 changes end: added logic for auto search @Himanshu 28-09-2022
     //CR00473 changes start: added download icon @Himanshu 04-03-2023
     downloadExe(){
      this.userService.getExeStore().subscribe(res=>{
        let downloadPath = res.data;
        if(downloadPath.length > 0){
        this.downloadService.download(environment.apiUrl + "downloadDocument?Path=" + encodeURIComponent(downloadPath[0].ValueDesc.replace(/\\/g, '\\')), localStorage.getItem('token'));
        }else{
          alert('Access Denied, Pls contact support Team');
        }
      });
    }
    //CR00473 changes start: added download icon @Himanshu 04-03-2023
    //DEF0003537  changes starts - @Sangamanath 1st_march_2024 
    getTargetSystems(){
      this.http.get<any>(environment.apiUrl + `configuration`).subscribe(res => {
        this.configdata=res.data;
      }, err => {
        alert(err.error.message);
      });
    }
    //DEF0003537  changes ends - @Sangamanath 1st_march_2024
    //DEF0003693 changes starts - @Sangamanath 5th july 2024 downloading extension in Breez  
    xpathExtractor(){
      this.downloadService.download(environment.apiUrl + "downloadDocument?Path=" + encodeURI("DownloadBreezXpathExtractorApplication.zip".replace(/\\/g, '\\'))+`&flag_path=${"6"}`, localStorage.getItem('token'));
    }
    //DEF0003693 changes ends - @Sangamanath 5th july 2024 downloading extension in Breez 
}
