<div class="container login-container">
  <div class="row">
    <div class="col-md-3"></div>
      <div class="col-md-6 login-form-1">
          <h3>Forgot Password</h3>
          <form (ngSubmit)="requestPass()" #requestPassForm="ngForm">
            <div class="form-group row">
              <label for="exampleInputEmail1" class="label">Enter your username and email address and we’ll send an OTP to reset your password</label>
              <label for="exampleInputPassword1" class="label">username</label>
              <input type="email" nbInput fullWidth name="username" id="user-name"
               [(ngModel)]="user.username" #username="ngModel"
              class="form-control" placeholder="Username"
              [class.form-control-danger]="username.invalid && username.touched"
              [required]="true"
              autofocus placeholder="username">
              <small class="form-text error" *ngIf="username.touched && username.invalid && username.errors?.required">
                Username is required!
              </small>
            </div>

            <div class="form-group row">
              <label for="exampleInputEmail1" class="label">Email address</label>
              <input type="email" nbInput 
              id="email" 
              [(ngModel)]="user.email"
              name="email"
              required
              email
              class="form-control" fullWidth placeholder="Email" #email="ngModel" pattern=".+@.+\..+">
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
                Email is required!
              </small>
              <small class="form-text error"
                     *ngIf="email.invalid && email.touched && email.errors?.pattern">
                Email should be the real one!
              </small>
  
            </div>
             <button type="submit" class="btn btn-primary active" [disabled]="submitted || !requestPassForm.form.valid"
                >Request Password</button>
             <div class="form-group">
              <a class="ForgetPwd" (click)="gotoLoginPage()">Already have an account? Sign In</a>
             </div>
          </form>
         </div>
      </div>
    </div>
  
  