import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

function downloadToLocal(binaryData, mimetype, fileName) {
    try {
        var blob = new Blob([binaryData], { type: mimetype });
        var fUrl = window.URL.createObjectURL(blob);
        var downloadLink = window.document.createElement('a');
        downloadLink.href = fUrl;
        if (downloadLink.download !== undefined)
            downloadLink.download = fileName;
        downloadLink.target = "_blank"; 
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } catch (err) {
     
    }
};

function ab2str(buf) {
    return JSON.parse(String.fromCharCode.apply(null, new Uint8Array(buf)));
}
@Injectable()
export class DownloadService {
    constructor(private http: HttpClient) { };
    download(sourceUrl, authToken): any {
        try {
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var contentDisposition = this.getResponseHeader("content-disposition");
                    var mimetype = this.getResponseHeader("content-type");
                    var fileName: any = contentDisposition ? contentDisposition.substring(contentDisposition.indexOf("=") + 1).replace(/\"/g, "") : null;
                    fileName = fileName ? fileName : (Date.now() + mimetype.substring(mimetype.indexOf("/") + 1, mimetype.indexOf(";")));
                    downloadToLocal(this.response, mimetype, fileName);
                }
                else if (this.readyState == 4 && this.status == 500) {
                    alert(ab2str(this.response).message);
                }         
            };
            xhttp.open("GET", sourceUrl, true);
            xhttp.setRequestHeader("Authorization", authToken);
            xhttp.responseType = 'arraybuffer';
            xhttp.send();
        } catch (err) {
           
        }
    };
}
