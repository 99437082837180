
import { Injectable } from '@angular/core';
@Injectable()
export class Storageservice {
    data: any;
    event: any;

    setdata(value: any) {
        this.data = value;
    }
    getdata() {
        return this.data;
    }
    setevent(value: any) {
        this.event = value;
    }
    getevent() {
        return this.event;
    }

}
