import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceLogin } from '../auth.service';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  result: any;
  value: any;
  email_Id: any;
  userName;
  //CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field
  passPattern=/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
  //CR00496 changes ends - @Sangamanath 09-Jan-2024 added validation on password field
  constructor(protected router: Router, private authService: AuthServiceLogin) { 
    this.authService.getUser()
      .subscribe(
        data => {
          this.value = data;
          this.email_Id = this.value[0].email_Id;
          this.userName = this.value[0].user_name;//DEF0003180 storing default value @Himanshu 15-02-2023
        });
   }

  ngOnInit(): void {
  }
  
  changePass(): void {
    this.errors = this.messages = [];
    this.submitted = true;
    if (this.user.password === this.user.confirmPassword) {
       //DEF0003180 changes start: added default value @Himanshu 15-02-2023
       this.user.email_Id = this.email_Id;
      this.user.username = this.userName;
       //DEF0003180 changes end: added default value @Himanshu 15-02-2023
      this.authService.changepassword(this.user)
        .subscribe(
          (data: any) => {
            if (data) {
              this.submitted = false;

              setTimeout(() => {
                alert("Successfully Updated");
                return this.router.navigateByUrl('login');
              }, this.redirectDelay);
            }

          },

          error => {
            alert(error.error.message);
            this.submitted = false;
            this.user = {};
          });
    }
    else {
      this.submitted = false;

    }
  }

}
