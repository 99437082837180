<!-- //DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login -->
<div class="container login-container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6 login-form-1">
      <h3></h3>
      <form (ngSubmit)="login()" #requestOtpForm="ngForm">
        <div class="form-group row">
          <label for="exampleInputEmail1" class="label">Enter your username and OTP</label>
          <input type="email" nbInput fullWidth name="username" id="user-name" [(ngModel)]="user.username"
            #username="ngModel" class="form-control" placeholder="Username"
            [class.form-control-danger]="username.invalid && username.touched" [required]="true" autofocus
            placeholder="username">
          <small class="form-text error" *ngIf="username.touched && username.invalid && username.errors?.required">
            Username is required!
          </small>
        </div>
        <div class="form-group row">
          <label for="exampleInputPassword1" class="label">OTP</label>
          <input (keypress)="_keyPress($event)" name="otp" [(ngModel)]="user.otp" type="otp" id="input-otp"
            class="form-control" placeholder="OTP(Only Number)" #otp="ngModel"
            [class.form-control-danger]="otp.invalid && otp.touched" [required]=true [minlength]=6 [maxlength]=6>
          <small class="form-text error" *ngIf="otp.invalid && otp.touched && otp.errors?.required">
            OTP is required!
          </small>
          <small class="form-text error"
            *ngIf="otp.invalid && otp.touched && (otp.errors?.minlength || otp.errors?.maxlength)">
            OTP should contains 6 characters
          </small>
        </div>

        <button type="submit" class="btn btn-primary active"
          [disabled]="submitted || !requestOtpForm.form.valid">Login</button>
      </form>
    </div>
  </div>
</div>
<!-- //DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login -->