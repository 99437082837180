import { Component, OnInit } from '@angular/core';
import { AuthServiceLogin } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  
  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  //CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field
  passPattern=/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
  //CR00496 changes ends - @Sangamanath 09-Jan-2024 added validation on password field
  constructor(protected router: Router, private authService: AuthServiceLogin) { }

  ngOnInit(): void {
  }

  _keyPress(e: any) {
    const pattern = /[0-9]/;
    let charCode = String.fromCharCode(e.charCode).toLowerCase();

    let keyCode = e.keyCode;
    // allow ctrl actions for firefox
    if (e.ctrlKey) {
      if (charCode == 'x' || charCode == 'c' || charCode == 'v') {
        return true;
      }
    }
    // Left / Up / Right / Down Arrow, Backspace, Delete keys
    if (keyCode == 37 || keyCode == 38 || keyCode == 39 ||
      keyCode == 40 || keyCode == 8) {
      return true;;
    }
    let inputChar = String.fromCharCode(e.charCode);

    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  }
  
  resetPass(): void {
    this.errors = this.messages = [];
    this.submitted = true;
    if (this.user.password === this.user.confirmPassword) {

      this.authService.resetPassWithOtp(this.user)
        .subscribe(
          (data: any) => {
            if (data) {
              this.submitted = false;

              setTimeout(() => {
                alert("Password Successfully Change")
             
                return this.router.navigateByUrl('login');
              }, this.redirectDelay);
            }
          },
          error => {
            alert(error.error.message);
            //DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login
          if(error.error.message==='You have Exceeded maximun no. of attempts please try after some time.'){
            //DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login
            this.submitted = false;
            this.user = {};
            //DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login
            return this.router.navigateByUrl('login');
          }else{
            this.submitted = false;
            this.user = {};
          }
          //DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login
          });
    }
    else {
      this.submitted = false;

    }
   
  }
  

}
