<div class="container login-container">
  <div class="row">
    <div class="col-md-3"></div>
      <div class="col-md-6 login-form-1">
          <h3>Reset Password</h3>
   
          <form (ngSubmit)="resetPass()"  #resetPassForm="ngForm">
            <div class="form-group">
              <label for="exampleInputEmail1" class="label">User Name</label>
              <input name="username" [(ngModel)]="user.username" id="user-name" #username="ngModel"
                 class="form-control" placeholder="Username"
                 [class.form-control-danger]="username.invalid && username.touched"
                 [required]="true"
                 autofocus>
          <small class="form-text error" *ngIf="username.invalid && username.touched && username.errors?.required">
            Username is required!
          </small>
  
            </div>
            <div class="form-group">
              <label for="exampleInputEmail2" class="label">Email Address</label>
              <input name="email" [(ngModel)]="user.email" id="input-email" #email="ngModel"
              class="form-control" placeholder="Email address" pattern=".+@.+\..+"
              [class.form-control-danger]="email.invalid && email.touched"
              [required]="true"
              >
            <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
              Email is required!
            </small>
            <small class="form-text error"
                    *ngIf="email.invalid && email.touched && email.errors?.pattern">
              Email should be the real one!
            </small>
           
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1" class="label">OTP</label>
            <input (keypress)="_keyPress($event)" name="otp" [(ngModel)]="user.otp" type="otp" id="input-otp"
            class="form-control" placeholder="OTP(Only Number)" #otp="ngModel"
            [class.form-control-danger]="otp.invalid && otp.touched"
            [required]=true
            [minlength]=6
            [maxlength]=6
            >
            <small class="form-text error" *ngIf="otp.invalid && otp.touched && otp.errors?.required">
              OTP is required!
            </small>
            <small
              class="form-text error"
              *ngIf="otp.invalid && otp.touched && (otp.errors?.minlength || otp.errors?.maxlength)">
              Password should contains 6 characters
            </small>
        </div>
          <div class="form-group">
            <label for="exampleInputPassword1" class="label">New Password</label>
            <input type="password" nbInput fullWidth id="exampleInputPassword1" name="password"
            [(ngModel)]="user.password"
            required
            class="form-control" #password="ngModel"
            [class.form-control-danger]="password.invalid && password.touched"
            [required]=true
            [pattern]="passPattern"
            [minlength]=0
            [maxlength]=10 placeholder="New Password">
            <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
                Password is required!
            </small>
            <small
                class="form-text error"
                *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
                Password should contains
                from 0
                to 10
                characters
            </small>
            <!-- //CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field -->
            <div class="text-red-400 text-sm" *ngIf="resetPassForm.controls['password'].dirty">
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['password'].value?.match('^(?=.*[A-Z])'),'text-red': !resetPassForm.controls['password'].value?.match('^(?=.*[A-Z])')}">At least one uppercase letter.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['password'].value?.match('(?=.*[a-z])'),'text-red': !resetPassForm.controls['password'].value?.match('(?=.*[a-z])')}">At least one lowercase letter.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['password'].value?.match('(.*[0-9].*)'),'text-red': !resetPassForm.controls['password'].value?.match('(.*[0-9].*)')}">At least one digit.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['password'].value?.match('(?=.*[!@#$%^&*])'),'text-red': !resetPassForm.controls['password'].value?.match('(?=.*[!@#$%^&*])')}">At least one special character.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['password'].value?.match('.{8,}'),'text-red': !resetPassForm.controls['password'].value?.match('.{8,}')}">At least 8 characters long.</span>
            </div>
            <!-- //CR00496 changes ends - @Sangamanath 09-Jan-2024 added validation on password field -->
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1" class="label">Confirm Password</label>
            <input type="password" nbInput fullWidth type="password" id="input-re-password"
            class="form-control form-control-lg last" placeholder="Confirm Password" name="rePass" [(ngModel)]="user.confirmPassword"
            required
            class="form-control" #rePass="ngModel"
            [class.form-control-danger]="(rePass.invalid || password.value != rePass.value) && rePass.touched"
            [required]=true [pattern]="passPattern" placeholder="Confirm Password"><!--//CR00496 changes starts - @Sangamanath 09-Jan-2024 added validation on password field-->
            <div class="text-red-400 text-sm" *ngIf="resetPassForm.controls['rePass'].dirty">
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['rePass'].value?.match('^(?=.*[A-Z])'), 'text-red': !resetPassForm.controls['rePass'].value?.match('^(?=.*[A-Z])')}">At least one uppercase letter.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['rePass'].value?.match('(?=.*[a-z])'), 'text-red': !resetPassForm.controls['rePass'].value?.match('(?=.*[a-z])')}">At least one lowercase letter.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['rePass'].value?.match('(.*[0-9].*)'), 'text-red': !resetPassForm.controls['rePass'].value?.match('(.*[0-9].*)')}">At least one digit.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['rePass'].value?.match('(?=.*[!@#$%^&*])'), 'text-red': !resetPassForm.controls['rePass'].value?.match('(?=.*[!@#$%^&*])')}">At least one special character.</span>
              <span class="block" [ngClass]="{'text-green': resetPassForm.controls['rePass'].value?.match('.{8,}'), 'text-red': !resetPassForm.controls['rePass'].value?.match('.{8,}')}">At least 8 characters long.</span>
            </div>
            <!-- //CR00496 changes ends - @Sangamanath 09-Jan-2024 added validation on password field -->
            <small class="form-text error"
            *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required">
            Password confirmation is required!
            </small>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary active" [disabled]="submitted || !resetPassForm.valid"
            >Change Password</button>
              
          </div>
          </form>
          <div class="links col-sm-12">
            <small class="form-text">
              Already have an account? <a routerLink="#"><strong>Sign In</strong></a>
            </small>
          </div>
      </div>
    </div>
  </div>
  