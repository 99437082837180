import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceLogin } from '../auth.service';

@Component({
  selector: 'ngx-login',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html',
})

export class RequestPasswordComponent implements OnInit {

  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  constructor(private authService: AuthServiceLogin, protected router: Router) { }

  ngOnInit(): void {
  }

  requestPass(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.authService.generateOtpEmail(this.user)
      .subscribe(
        (data: any) => {
          if (data) {
            setTimeout(() => {
              alert(data.data);
              return this.router.navigateByUrl('reset');
            }, this.redirectDelay);
          }
        },
        error => {
              
          alert("Username or EmailId is not correct");

          this.submitted = false;
          this.user = {};
        });
  }

  gotoLoginPage() {
    this.router.navigateByUrl('/login')
  }

}
