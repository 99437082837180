import { Injectable, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import 'rxjs/add/operator/toPromise';

//changes Begin: importing environment.ts file
//#CR0019 #Pratibha  #SPRINT1/2018 #27July2018
//changes End: importing environment.ts file
//#CR0019 #Pratibha  #SPRINT1/2018 #27July2018

//changes Begin: importing broadcast.service file done by senthil
//#DEF00332 #Pratibha  #SPRINT1/2018 #24Sept2018
import { environment } from '../../../../environments/environment';
import { DashboardBroadcaster } from './dashboard.broadcast.service';
//changes End:importing broadcast.service file
//#DEF00332 #Pratibha  #SPRINT1/2018 #24Sept2018

//import { HeaderComponent } from "..///../../../../header.component";
let counter = 0;

@Injectable()
export class UserService {

  // public users = {
  //    name: null, 
  //    picture: null 
  //   // eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
  //   // jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
  //   // lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
  //   // alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
  //   // kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  // };

  private userArray: any[];
  public emplist: any[];
  empName: any[];
  username: any[];
  someImage: ElementRef;
  imageData: any;
  users;
  empCode;
  //change begin:Creation of Landing Page Screen
  // #BRD0010 #Hrishikesh #5 sept 2018
  emp_First_Name;
  //change end:Creation of Landing Page Screen
  // #BRD0010 #Hrishikesh #5 sept 2018
  constructor(
    //  private fb: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private profileBroadcaster: DashboardBroadcaster // injected DashboardBroadcaster #DEF00332 #pratibha #24Sept 2018
    // private router: Router,
  ) {
    //this.userArray = Object.values(this.users);
  }
  //changes Begin: implementation of getUsers() method and calling usersubdomainrole API to get employeeCode
  //#BRD0044 #Pratibha  #SPRINT1/2018 #8Jun2018
  getUsers() {
    let userID = sessionStorage.getItem('user_id');
    return this.http
      //  changes Begin: URL changes for caling API
      //  #CR0019 #Pratibha  #SPRINT1/2018 #27July2018
      // .get<any>(`http://192.168.0.111:8080/usersubdomainrole?user_Id=${userID}`)
      .get<any>(environment.apiUrl + `usersubdomainrole?user_Id=${userID}`)
      //  changes End: URL changes for caling API
      //  #CR0019 #Pratibha  #SPRINT1/2018 #27July2018
      .map((response) => {
        //  changes Begin:in local storage storing domain_Code and subdomain_code
        //  #CR0041 #Pratibha  #SPRINT1/2018 #03-Dec-2018
        localStorage.setItem('domain_Code', response.data[0].domain_Code);
        localStorage.setItem('subdomain_Code', response.data[0].subdomain_Code);
        //  changes End: in local storage storing domain_Code and subdomain_code
        //  #CR0041 #Pratibha  #SPRINT1/2018 #03-Dec-2018
        return response.data
      });
  }
  //changes End:

  //changes Begin: implementation of getUsers() method and calling getemployeeservice API to get employee firstName and lastName
  //#BRD0044 #Pratibha  #SPRINT1/2018 #8Jun2018
  getEmpDetail(empCode) {
    // changes Begin: commenting console.log()
    // #DEF00497 #Pratibha  #SPRINT1/2018 #26-Nov-2018
    // console.log("getuser");
    // console.log("inside empDetails", empCode);
    // changes End: commenting console.log()
    // #DEF00497 #Pratibha  #SPRINT1/2018 #26-Nov-2018
    return this.http
      //  changes Begin: URL changes for caling API
      //  #CR0019 #Pratibha  #SPRINT1/2018 #27July2018
      // .get<any>(`http://192.168.0.111:8080/manageemp/getemployeeservice?emp_Code=${empCode}`)
      .get<any>(environment.apiUrl + `manageemp/getemployeeservice?emp_Code=${empCode}`)
      //  changes End: URL changes for caling API
      //  #CR0019 #Pratibha  #SPRINT1/2018 #27July2018
      .map((response) => {
        //change begin:Creation of Landing Page Screen
        // #BRD0010 #Hrishikesh #5 sept 2018

        //changes Begin: commented the localstorage and using broadcaster done by #Senthilkumar -- Broadcasting profile info through dashboard broadcaster
        //#DEF00332 #Pratibha  #SPRINT1/2018 #24Sept2018
        // localStorage.setItem("emp_First_Name", response.data[0].emp_First_Name);
        // this.profileBroadcaster.broadcastProfileInfo({ "firstName": response.data[0].emp_First_Name });
        console.log(response);
        //changes End: commented the localstorage and using broadcaster done by #Senthilkumar -- Broadcasting profile info through dashboard broadcaster
        //#DEF00332 #Pratibha  #SPRINT1/2018 #24Sept2018

        //change end:Creation of Landing Page Screen
        // #BRD0010 #Hrishikesh #5 sept 2018  

        // changes Begin: commenting console.log()
        // #DEF00497 #Pratibha  #SPRINT1/2018 #26-Nov-2018
        // console.log(response.data);
        // changes End: commenting console.log()
        // #DEF00497 #Pratibha  #SPRINT1/2018 #26-Nov-2018
        return response.data;
      })
  }
  //changes End:

  //changes Begin: implementation of getUsers() method and calling getorgbyname API to get orgName
  //#BRD0044 #Pratibha  #SPRINT1/2018 #8Jun2018
  getOrgname(): Observable<HttpResponse<any>> {
    let orgCode = sessionStorage.getItem('org_Code')
    return this.http
      //  changes Begin: URL changes for caling API
      //  #CR0019 #Pratibha  #SPRINT1/2018 #27July2018
      // .get<any>(`http://192.168.0.111:8080/manageorg/getorgbyname?org_Code=${orgCode}`)
      .get<any>(environment.apiUrl + `manageorg/getorgbyname?org_Code=${orgCode}`)
      //  changes End: URL changes for caling API
      //  #CR0019 #Pratibha  #SPRINT1/2018 #27July2018
      .map((response) => {
        // changes Begin: commenting console.log()
        // #DEF00497 #Pratibha  #SPRINT1/2018 #26-Nov-2018
        // console.log(response.data);
        // changes End: commenting console.log()
        // #DEF00497 #Pratibha  #SPRINT1/2018 #26-Nov-2018

        // this.emplist=response.data;
        return response.data;


      })
  }
  //changes End:
  getUserArray(): Observable<any[]> {
    return Observable.of(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return Observable.of(this.userArray[counter]);
  }
  //CR00473 changes start: calling setupvalue api @Himanshu 04-03-2023
  getExeStore() {
    return this.http.get<any>(environment.apiUrl + `manage/getsetupvalue?type_Code=EXE-STORE&setupValue_Val_Cd=PATH`)
  }
  //CR00473 changes end: calling setupvalue api @Himanshu 04-03-2023
 //DEF0003491 changes starts - @Sangamanath 12-FEB-2024 logout issue
  logOut() {
    return this.http.post<any>(environment.apiUrl + `auth/logout`, "");
  }
   //DEF0003491 changes ends - @Sangamanath 12-FEB-2024 logout issue
}
