//DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login
import { Component, OnInit } from '@angular/core';
import { AuthServiceLogin } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-otp-component',
  templateUrl: './otp-component.component.html',
  styleUrls: ['./otp-component.component.scss']
})
export class OtpComponentComponent implements OnInit {
  user: any = {};
  submitted = false;
  redirectDelay: number = 0;

  constructor(private authService: AuthServiceLogin, protected router: Router) {

    this.user = {
      username: localStorage.getItem("user_name")
    };
  }

  ngOnInit(): void {
  }

  login(): void {


    this.submitted = true;

    this.authService.enterOtp(this.user)
      .subscribe(
        (data: any) => {
          if (data) {
            setTimeout(() => {
              alert(data.body.data);
              return this.router.navigateByUrl('pages');
            }, this.redirectDelay);
          }
        },
        error => {

          alert(error.error.message);
         
          this.submitted = false;
          this.user = {};
          return this.router.navigateByUrl('login');
        });


  }
  _keyPress(e: any) {
    const pattern = /[0-9]/;
    let charCode = String.fromCharCode(e.charCode).toLowerCase();

    let keyCode = e.keyCode;
    // allow ctrl actions for firefox
    if (e.ctrlKey) {
      if (charCode == 'x' || charCode == 'c' || charCode == 'v') {
        return true;
      }
    }
    // Left / Up / Right / Down Arrow, Backspace, Delete keys
    if (keyCode == 37 || keyCode == 38 || keyCode == 39 ||
      keyCode == 40 || keyCode == 8) {
      return true;;
    }
    let inputChar = String.fromCharCode(e.charCode);

    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  }

}
//DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login
