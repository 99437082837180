<div class="container login-container">
  <div class="row">
    <div class="col-md-3"></div>
      <div class="col-md-6 login-form-1">
          <h3>Sign In</h3>
          <form (ngSubmit)="login()" #form="ngForm" autocomplete="nope">
            <small class="form-text sub-title">Hello! Sign in with your username or email</small>
            <div class="form-check-inline">
              <label class="form-check-label" >
                <input type="radio" (change)="userChecked($event)" class="form-check-input" name="optradio"
                >With User Name
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input type="radio" (change)="userunChecked($event)" class="form-check-input" name="optradio">With Email Id
              </label>
            </div>
            <div class="form-group row email" *ngIf="isLogin && !submitted">
              <label for="exampleInputEmail1" class="label">Email address</label>
              <input type="email" nbInput 
              id="email" 
              [(ngModel)]="user.email"
              name="email"
              required
              email
              class="form-control" fullWidth placeholder="Email" #email="ngModel" pattern=".+@.+\..+">
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
                Email is required!
              </small>
              <small class="form-text error"
                     *ngIf="!Organization && email.invalid && email.touched && email.errors?.pattern">
                Email should be the real one!
              </small>
            </div>
          
            <div class="form-group row" *ngIf="isLogin && !submitted">
              <label for="exampleInputPassword1" class="label">Password</label>
              <input type="password" nbInput fullWidth type="password"
              id="input-password"
              name="password"
              [(ngModel)]="user.password"
              class="form-control"
              #password="ngModel"
              [class.form-control-danger]="password.invalid && password.touched"
              [required]="true"
              [minlength]=0
              [maxlength]=10 placeholder="Password">
           
            <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
              Password is required!
            </small>
            <small
              class="form-text error"
              *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
              Password should contains
              from 0
              to 10
              characters
            </small>
          </div>
          <div class="form-group row" *ngIf="Organization">
            <label for="exampleInputPassword1" class="label">Organization Code</label>
            <input type="text" nbInput 
              id="org_Code" 
              [(ngModel)]="user.org_Code"
              name="org_Code"
              required
              org_Code
              class="form-control" fullWidth placeholder="organization code" #org_code="ngModel">
              <small class="form-text error" *ngIf="org_Code.invalid && org_Code.touched && org_Code.errors?.required">
                Organization Code is Mandatory with user name
              </small>
        </div>
          <div class="form-group row" *ngIf="!isLogin">
            <label for="input-subdomain">Subdomain *</label>
            <select name="subdomain" [(ngModel)]="user.subdomain_Code"
             placeholder="Subdomain *" class="form-control"
            id="input-subdomain" #subdomain="ngModel"
             [class.form-control-danger]="subdomain.invalid && subdomain.touched" autofocus
                   [required]=true>
              <option *ngFor="let subdomain of subdomains" [value]="subdomain.subdomain_Code">
                {{ subdomain.subdomain_Name }}
              </option>
            </select>
            <small class="form-text error" *ngIf="subdomain.invalid && subdomain.touched && subdomain.errors?.required">
              Subdomain is required!
            </small>
          </div>
  
          <div class="form-group row" *ngIf="isLogin && submitted">
            <span class="forgot-password"> Please wait while redirecting...</span>
          </div>
            <div class="form-group row">
              <div class="col-sm-6">
                <input type="checkbox">
               Remember Me
              </div>
              <div class="col-sm-6">
                <a class="forgot-password" class="ForgetPwd" (click)="gotoForgetPass()">Forgot Password?</a>
              </div>
            </div>
              <div class="form-group">
                <!-- //DEF0003511 changes starts- @Sangamanath 13-FEB-2024 two way authentication for login -->
                  <!-- <button type="submit" class="btnSubmit" [disabled]="submitted || !form.valid">Login</button>  -->
                  <button type="submit" class="btnSubmit" [disabled]="submitted || !form.valid">Submit</button> 
                  <!-- //DEF0003511 changes ends- @Sangamanath 13-FEB-2024 two way authentication for login -->
              </div>
              
          </form>
      </div>
      
       </div>
       </div>
