<!--CR00413 CHnages start added changes for model @Sujitha 2-Nov-2021-->
<nb-card>
    <nb-card-header>
        <div class="row">
            <!--DEF0003071 changes start: modified the heading @Himanshu 22-Nov-2022-->
            <!-- <div> -->
                <!--CR00413 Changes start changed the text @Sujitha 8-Nov-2021-->
                <!-- List of Systems -->
                <!-- Select System -->
                <!--CR00413 Changes End changed the text @Sujitha 8-Nov-2021-->
            <!-- </div> -->
            <!-- <div style="margin-left: 188px;">
                <button type="button" nbButton status="primary" (click)="onCreate()">Create System</button>
            </div> -->
            <div class="col-md-9"><strong>Click on "Create Target System" to start Automation Journey</strong></div>
            <div class="col-md-3"><button type="button" nbButton status="primary" (click)="onCreate()">Create Target System</button></div>
             <!--DEF0003071 changes end: modified the heading @Himanshu 22-Nov-2022-->
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <ng2-smart-table id="table1" [settings]="settings" [source]="source" (custom)="onCustom($event)">
            </ng2-smart-table>
        </div>

    </nb-card-body>
    <!-- <nb-card-footer>
        <button nbButton hero status="primary" (click)="closeModal()">Close</button>
    </nb-card-footer> -->
</nb-card>
<!--CR00413 CHnages End added changes for model @Sujitha 2-Nov-2021-->