
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
// import { JwtHelper } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

  // constructor(public jwtHelper: JwtHelper) {}
  //DEF0003489 changes starts- role permission @Sangamanath 31-Jan-2024
  // constructor() {}
  constructor(private http:HttpClient) {}
  //DEF0003489 changes ends- role permission @Sangamanath 31-Jan-2024
  public isAuthenticated(): boolean {
     //DEF0002497 changes start: uncomment below code by referring attest code @Himanshu 03-02-2022
   	// if(localStorage.getItem('token'))
     if(localStorage.getItem('token'))
      //DEF0002497 changes end: uncomment below code by referring attest code @Himanshu 03-02-2022
		return true;
	return false;
    // return !this.jwtHelper.isTokenExpired(token); 
    // no need to check if token is expired in client we will check it in server by checking it will add another dependency
  }
  //DEF0003489 changes starts- role permission @Sangamanath 31-Jan-2024
  getMenus() {
      return this.http.get<any>(environment.apiUrl + `managemenu/menu`);
  }
  //DEF0003489 changes ends- role permission @Sangamanath 31-Jan-2024
}