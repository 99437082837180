import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
        <!--#CR00376 #Pavithra #22Jun2021- Changes for New Layout -->
        <nb-sidebar-footer class="nb-sidebar-footer">
        <!--#DEF0002391 #Himanshu #30Aug2021- Changes for New logo -->
        <!--<img src="assets/images/sertfy-logo.png" width="208" height="56">-->
            <img src="assets/images/breez.png" width="208" height="56">
            <!--#DEF0002391 #Himanshu #30Aug2021- Changes for New logo -->
        </nb-sidebar-footer>
        <!--#CR00376 #Pavithra #22Jun2021- Changes for New Layout -->
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!--#CR00376 #Pavithra #22Jun2021- Changes for New Layout <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
      <!--#CR00376 #Pavithra #22Jun2021- Changes for New Layout-->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {}
